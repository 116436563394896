import React from "react"
import { Alert, Form, Spin } from "antd"
import { ActiveCampaignValues, Email, Name, Submit, UTMParams } from "./Fields"
import API from "../../utils/api"

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field])
}

class OptIn extends React.Component {
  state = {
    formSubmitted: false,
    formSuccess: false,
  }

  componentDidMount() {
    this.props.form.validateFields()
  }

  handleSubmit = event => {
    event.preventDefault()
    this.setState({ formSubmitted: true })
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const query = `mutation {
          triggerActiveCampaignAutomations(
            form_name:"${values.form_name}",
            automation_id:"${values.automation_id}",
            sync_deal:"false",
            utm_medium:"${values.utm_medium}",
            utm_source:"${values.utm_source}",
            utm_campaign:"${values.utm_campaign}",
            email:"${values.email}",
            name:"${values.name}"
          ){
            error
            result
          }
        }`
        const res = await API.post("", {
          query: query,
        })
        if (res.status !== 404) {
          this.setState({ formSuccess: true })
        }
      }
    })
  }

  render() {
    const {
      getFieldDecorator,
      getFieldsError,
      getFieldError,
      isFieldTouched,
    } = this.props.form
    const nameError = isFieldTouched("name") && getFieldError("name")
    const emailError = isFieldTouched("email") && getFieldError("email")

    return (
      <>
        {!this.state.formSuccess ? (
          <Spin spinning={this.state.formSubmitted && !this.state.formSuccess}>
            <Form
              onSubmit={this.handleSubmit}
              style={{ textAlign: `left`, width: `100%` }}
            >
              <ActiveCampaignValues
                automationId={this.props.automationId}
                decorator={getFieldDecorator}
                formName={this.props.formName}
              />
              <UTMParams decorator={getFieldDecorator} />
              <Name decorator={getFieldDecorator} validator={nameError} />
              <Email decorator={getFieldDecorator} validator={emailError} />
              <Submit
                id={this.props.gtmClickId}
                disabled={hasErrors(getFieldsError())}
              />
            </Form>
          </Spin>
        ) : (
          <Alert
            message={`Nice!`}
            description="Thanks for signing up! We'll keep you up to date with our best offers, and will notify you 24 hours before the general public when game day room blocks are released."
            type="success"
            showIcon
          />
        )}
      </>
    )
  }
}

const OptInForm = Form.create({ name: "opt_in_form" })(OptIn)

export default OptInForm
